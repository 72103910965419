@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
body{
  font-family: "Josefin Sans", serif; 
  font-size: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.808);
}
*{
  margin: 0;
  padding: 0;
}
.title{
  text-align: center;
  text-transform:capitalize ;
  color: orange;
  margin-bottom: 0;
  margin-top: 3px;
}
.container_Calic{
  width: fit-content;
  height: fit-content;
  background-color: #190f14c9;
  margin: auto;
  z-index: 2;
  margin-top: 15px; 
  box-shadow:  10px 5px 5px red  ;
}
.container_Calic button {
  margin: 11px;
  padding: 15px;
  width: 65px;
  height: 65px;
  font-size: 19px;
  font-weight: bold;
  color:#190f14c9 ;
  background-color: orange;
  border: 2px solid rgba(0, 0, 0, 0.427);
  border-radius: 50%;
}
input{
  font-size: 28px;
  color: blue;
  z-index: 2;
  border-radius: 12px;
  margin: 11px;
  padding: 17px;
  width: 285px;
  height: 65px;
}
@media (max-width: 575px) {
  .container_Calic{
    width: 100%;
    height: 100vh;
  }
  .container_Calic button{
    margin: 5px;
    font-size: 16px;
  }
  input{
    width: 230px;
    height: 35px;
  }
}



